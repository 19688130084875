//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      applications: [
        {
          title: "Hospital (HMS)",
        },
        {
          title: "Clinic (CMS)",
        },
        {
          title: "Laboratories (LIS)",
        },
        {
          title: "Pharmacy (PIS)",
        },
        {
          title: "Member Portal",
        },
        {
          title: "Patient Portal",
        },
        {
          title: "Insurer Portal",
        },

        {
          title: "TPA System",
        },
      ],
      cores: [
        {
          title: "Document & File Management",
        },
        {
          title: "Messages & Notifications",
        },
        {
          title: "Contract Management",
        },
        {
          title: "Billing & Accouting",
        },
        {
          title: "Authentication & Authorization",
        },
      ],
      healths: [
        {
          title: "Prescriptions",
        },
        {
          title: "Appointments",
        },
        {
          title: "Inventory",
        },
        {
          title: "Medical Records",
        },
        {
          title: "Queues & Ticketing",
        },
      ],
      insurances: [
        {
          title: "Insurance Policies",
        },
        {
          title: "Health Claims",
        },
        {
          title: "Insurance Plans",
        },
        {
          title: "Claim Ajudication Engine",
        },
      ],
      datas: [
        {
          title: "Any Database",
        },
        {
          title: "Any File System",
        },
        {
          title: "Any Datalake",
        },
      ],
    };
  },
};
